import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MailIcon from '@mui/icons-material/Mail';
import SmsIcon from '@mui/icons-material/Sms';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useStyles } from "./styles";
import {get, post} from "./commons";

export default function WorkReport({biz,shiftsigned,managers,projects,userData,privilegesModel,retrieveBizData}) {
  const classes = useStyles();
  const [totalRows, setTotalRows] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [employeesData, setEmployeesData] = React.useState([]);
  const [employeesDataFiltered, setEmployeesDataFiltered] = React.useState([]);
  const [displayEmployeesData, setDisplayEmployeesData] = React.useState([]);
  const [order, setOrder] = React.useState({});
  const [model, setModel] = React.useState();
  const [isSaved, setisSaved] = React.useState(false);
  const [isSent, setisSent] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [activateUser, setActivateUser] = React.useState();
  const [privilegesReview, setPrivilegesReview] = React.useState();
  const [actives, setActives] = React.useState(true);

  const sorting = (items,field,modelJSON) => {
    let newOrder = {...order}
    newOrder[field] = (newOrder[field]) ? !newOrder[field] : true;
    setOrder(newOrder);
    let modelData = (modelJSON) ? modelJSON : model;
    if (modelData && modelData.references) {
      let modelType = modelData.references.filter((e)=>e.db===field);
      if (modelType.length > 0) {
        let type = modelType[0].type;
        return items.sort(function(a, b) {
          let alower, blower;
          if (type === "text") {
            alower = (a[field]) ? a[field].toLowerCase() : null;
            blower = (b[field]) ? b[field].toLowerCase() : null;
          }
          else if (type === "number") {
            alower = (a[field]) ? parseInt(a[field]) : null;
            blower = (b[field]) ? parseInt(b[field]) : null;
          }
          if (alower && blower) {
            if (newOrder[field]) {
              if (alower > blower) return 1;
              if (alower < blower) return -1;
            }
            else {
                if (alower < blower) return 1;
                if (alower > blower) return -1;
              }
          }
          return 0;
        });
      }
    }
  }

  const retriveDataDB = () => {
    get(`/server/request/getemployeesfullset`).then((response)=>{
      let modelJSON;
      if (response.model) {
        modelJSON = JSON.parse(response.model[0].model);
        if (modelJSON) {
          setModel(modelJSON);
        }
      }
      if (response.roles) {//console.log(userData)
        if (userData && userData.role) setRoles(response.roles)//setRoles(response.roles.filter((e)=>e.id < userData.role))
        else setRoles(response.roles);
      }
      if (response.employees) {
        let allEmployeesSorted = sorting(response.employees,"name",modelJSON);
        let allEmployees = allEmployeesSorted.map((e)=>{
          if (e.email === null) e.email = "";
          if (e.mobile === null) e.mobile = "0034";
          return e;
        })
        setEmployeesData(allEmployees);//console.log(allEmployees)
        setEmployeesDataFiltered(allEmployees);
        let employeesDataSet = allEmployees.map((e)=>{
          if ((e.active && actives) || (!e.active && !actives)) {
            let data2set = {...e}
            if (modelJSON && (!e.mobile || e.mobile.length === 0)) data2set.mobile = modelJSON.phonecode
            return data2set
          }
          else return null;
        }).filter(e=>e);
        setDisplayEmployeesData(employeesDataSet.slice(0,rowsPerPage));
        setTotalRows(employeesDataSet.length);
      }
    });
  }

  useEffect(()=>{
    let employeesDataSet = employeesDataFiltered.map((e)=>{
      if ((e.active === 1 && actives) || (e.active === 0 && !actives)) return e;
      else return null;
    }).filter(e=>e);
    setDisplayEmployeesData(employeesDataSet.slice(0,rowsPerPage));
    setTotalRows(employeesDataSet.length);
    setPage(0);
  },[actives]);

  useEffect(()=>{
    retriveDataDB()
  },[]);

  const reorder = (field) => {
    let allEmployees = sorting(employeesDataFiltered,field);
    setEmployeesDataFiltered(allEmployees);
    setPage(0);
    let employeesDataSet = allEmployees.map((e)=>{
      if ((e.active && actives) || (!e.active && !actives)) return e;
      else return null;
    }).filter(e=>e).slice(0,rowsPerPage);
    setDisplayEmployeesData(employeesDataSet);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    let start = newPage*rowsPerPage;
    let end = newPage*rowsPerPage+rowsPerPage;
    let employeesDataSet = employeesDataFiltered.map((e)=>{
      if ((e.active && actives) || (!e.active && !actives)) return e;
      else return null;
    }).filter(e=>e).slice(start,end);
    setDisplayEmployeesData(employeesDataSet);
  };
  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
    let employeesDataSet = employeesDataFiltered.map((e)=>{
      if ((e.active && actives) || (!e.active && !actives)) return e;
      else return null;
    }).filter(e=>e).slice(0,rows);
    setDisplayEmployeesData(employeesDataSet);
  };
/*  const showProject = (row,index) => {
    if (row.code)
      get(`/server/request/getworkorder?id=${row.code}`).then((response)=>{
        if (response.workorder) {
          let newworkOrderDetails = [...workOrderDetails];
          newworkOrderDetails[index] = response.workorder;
          setWorkOrderDetails(newworkOrderDetails);
        }
      });
  }*/
  const filterData = (text) => {
    let filteredemployeesData = (text) ? employeesData.filter((e)=>{
      if (e.name && e.name.toLowerCase().indexOf(text.toLowerCase())>-1) return true;
      else if (e.code && e.code.indexOf(text)>-1) return true;
      else return false;
    }) : employeesData;
    setTotalRows(filteredemployeesData.length);
    setPage(0);
    setEmployeesDataFiltered(filteredemployeesData);
    let employeesDataSet = filteredemployeesData.map((e)=>{
      if ((e.active && actives) || (!e.active && !actives)) return e;
      else return null;
    }).filter(e=>e).slice(0,rowsPerPage);
    setDisplayEmployeesData(employeesDataSet);
  };
  const selectFile = (event) => {
    var reader = new FileReader();
    reader.onload = function(){
      let text = reader.result.replace(/\r/gi,"");
      if (model.divider) {
        let rows = text.split("\n");
        let headers = [];
        if (!model.header) {
          headers = model.references.map((header)=>{
            if (header.db) return header.db;
            else return null;
          })
        }
        let newData = rows.map((e,i)=>{
          if (model.header && i===0) {
            headers = e.split(model.divider).map((e)=>{
              let val = model.references.filter((f)=>f.id===e);
              if (val[0] && val[0].db) return val[0].db;
              else return null;
            })
            return null;
          }
          else {
            let rowData = e.split(model.divider);
            if (rowData.length === headers.length) {
              let newVal = {}
              headers.forEach((e,i)=>{//console.log(e,i,newVal[e],rowData[i])
                if (e) newVal[e] = rowData[i];
              })
              return newVal
            }
            else return null;
          }
        }).filter(n=>n);
        if (newData) {
          let activeModel = model.references.filter((e)=>e.db==="active");
          let data2send = (!activeModel[0] && !activeModel[0].istrue) ? newData : newData.map((e)=>{//console.log(e.active,activeModel[0].istrue)
            return {...e,active:Boolean(e.active===activeModel[0].istrue)}
          })
          post(`/server/request/setemployees`,{data2send}).then((response)=>{
            if (response.updated) {
              retriveDataDB();
              setisSaved(true);
              retrieveBizData();
            }
          });
        }
      }
    };
    reader.readAsText(event.target.files[0],'ISO-8859-1');
  }
  const recordDisplayData = (field,pos,event) => {
    let newdisplayEmployeesData = [...displayEmployeesData];
    newdisplayEmployeesData[pos][field] = event.target.value;
    setDisplayEmployeesData(newdisplayEmployeesData);
  }
  const recordEmployeeData = (field,pos,event) => {
    let newemployeesData = [...employeesData];
    newemployeesData[pos][field] = event.target.value;
    setEmployeesData(newemployeesData);//console.log(employeesData,newemployeesData)
  }
  const updateVal = (field,e,i,event) => {
    let value = event.target.value;
    post(`/server/request/setemployee`,{field:field,id:e.id,code:e.code,name:e.name,val:value}).then((response)=>{
      if (response.updated) {
        setisSaved(true);
        recordDisplayData(field,i,event);
        recordEmployeeData(field,i,event);
      }
    });
  }
  const sendEmail = (e,i) => {
    setActivateUser({type:"email",email:e.email,user:e.id})
  }
  const sendSMS = (e,i) => {
    setActivateUser({type:"mobile",mobile:e.mobile,user:e.id})
  }
  const hiddeDialog = (e,i) => {
    setActivateUser()
  }
  const sendSMSActivation = (e,i) => {
    post(`/server/request/newsms4user`,{user:activateUser.user,mobile:activateUser.mobile}).then((response)=>{
      if (response.success) {
        setisSent(true);
        hiddeDialog();
      }
    });
  }
  const sendEmailActivation = (e,i) => {
    post(`/server/request/newpass4user`,{user:activateUser.user,email:activateUser.email}).then((response)=>{
      if (response.success) {
        setisSent(true);
        hiddeDialog();
      }
    });
  }
  const retriveprivileges = (e,i) => {
    get(`/server/request/retriveprivileges?id=${e.code}`).then((response)=>{
      if (response.privileges && privilegesModel) {
        let keys = Object.keys(privilegesModel);
        let privilegesArray = keys.map((e)=>{return {type:e,val:(response.privileges[e]) ? response.privileges[e] : privilegesModel[e].default,name:privilegesModel[e].name}})
        setPrivilegesReview({data:privilegesArray, employee:e.code, response:response.privileges})
      }
    });
  }
  const changePrivilege = (e,i) => {
    let val = (e.val === "true") ? "false" : "true";
    let newPrivReview = {...privilegesReview};
    let response = newPrivReview.response;
    newPrivReview.data.forEach((f)=>{
      if (f.type === e.type) response[e.type] = val;
    })
    newPrivReview.data[i].val = val;
    post(`/server/request/changeprivilege`,{data:response,employee:privilegesReview.employee}).then((response)=>{
      if (response.success) {//console.log(newPrivReview);
        setPrivilegesReview(newPrivReview);
      }
    });
  }
  return (
    <Container maxWidth="lg" className={classes.contentTop}>
      <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
        <Grid item lg={12}>
          <Typography variant="h5" color="textSecondary">
            Empleados
          </Typography>
        </Grid>
        <Grid item lg={3} id="search">
          <FormControl variant="standard">
            <InputLabel htmlFor="search">
              Buscar
            </InputLabel>
            <Input
              id="search"
              autoFocus
              onChange={(e)=>filterData(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
          <Grid item lg={3}>
            <Typography variant="body2" color="textSecondary" className={classes.end}>
              <FormControlLabel control={<Switch checked={actives} />} label="activos" onClick={()=>setActives(!actives)}/>
            </Typography>
          </Grid>
        {model && model.divider &&
          <Grid item lg={6} id="load" className={classes.end}>
            <input
              accept='text/txt'
              className={classes.nodisplay}
              id="uploaDOC"
              multiple
              type="file"
              name="csvemployees"
              onChange={(e)=>{selectFile(e)}}
            />
            <label htmlFor="uploaDOC" >
              <Button
                variant="outlined"
                startIcon={<UploadIcon/>}
                size="small"
                component="span"
                className={classes.button1}
              > Subir fichero</Button>
            </label>
          </Grid>
          }
        {displayEmployeesData.length > 0 &&
        <Grid item lg={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key="name" className={classes.strongpointer} onClick={()=>reorder("name")}>Nombre</TableCell>
                  <TableCell key="code" className={classes.strongpointer} onClick={()=>reorder("code")} align="right">Código</TableCell>
                  <TableCell key="role" className={classes.strongpointer} onClick={()=>reorder("role")} align="right">role</TableCell>
                  <TableCell key="email" className={classes.strongpointer} onClick={()=>reorder("email")} align="right">email</TableCell>
                  <TableCell key="mobile" className={classes.strongpointer} onClick={()=>reorder("mobile")} align="right">móvil</TableCell>
                  <TableCell key="privileges" align="right">permisos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayEmployeesData.filter(e=>Boolean(e.active)===actives).map((e,i) => (
                  <>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <>
                        <TableCell key={"name"+i}>{e.name}</TableCell>
                        <TableCell key={"code"+i} align="center">{e.code}</TableCell>
                        <TableCell key={"role"+i} align="center">
                          <FormControl fullWidth>
                            <InputLabel id="role">role</InputLabel>
                            <Select
                              id="role"
                              value={e.role}
                              onChange={(event)=>updateVal("role",e,i,event)}
                              disabled={e.role >= userData.role}
                              >
                              {e.role >= userData.role && roles.map((option,index4)=>
                                    <MenuItem value={option.id} key={index4} selected={option.id===e.role} >{option.description}</MenuItem>
                              )}
                              {e.role < userData.role && roles.filter((f)=>f.id < userData.role).map((option,index4)=>
                                    <MenuItem value={option.id} key={index4} selected={option.id===e.role} >{option.description}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell key={"email"+i} align="center">
                          <TextField
                            id="emailTextfield"
                            key={"emailTextfield"+i}
                            variant="outlined"
                            value={e.email}
                            onChange={(event)=>recordDisplayData("email",i,event)}
                            onBlur={(event)=>updateVal("email",e,i,event)}
                            label="email"
                            fullWidth
                            className={classes.blue}
                            InputProps={e.email && e.email.length > 5 && {
                              endAdornment: <InputAdornment position="end">
                                              <MailIcon onClick={()=>sendEmail(e,i)}/>
                                            </InputAdornment>
                            }}/>
                          </TableCell>
                          <TableCell key={"mobile"+i} align="center">
                            <TextField
                              id="mobileTextfield"
                              key={"mobileTextfield"+i}
                              variant="outlined"
                              value={e.mobile ? e.mobile.toString() : "0034"}
                              onChange={(event)=>recordDisplayData("mobile",i,event)}
                              onBlur={(event)=>updateVal("mobile",e,i,event)}
                              label="Móvil"
                              fullWidth
                              className={classes.blue}
                              InputProps={e.mobile && e.mobile.length > 5 && {
                                endAdornment: <InputAdornment position="end">
                                                <SmsIcon onClick={()=>sendSMS(e,i)}/>
                                              </InputAdornment>
                              }}/>
                            </TableCell>
                            <TableCell key={"privileges"+i} align="center">
                              <LocalPoliceIcon onClick={()=>retriveprivileges(e,i)} className={classes.strongpointer}/>
                            </TableCell>
                      </>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Filas:</span>}
            labelDisplayedRows={({ page }) => `Página ${page+1} de ${Math.ceil(totalRows/rowsPerPage)}`}
          />
        </Grid>
        }
      </Grid>
      <Snackbar open={isSaved} autoHideDuration={6000} onClose={()=>setisSaved(false)}>
        <MuiAlert elevation={6} variant="filled"  onClose={()=>setisSaved(false)} severity="success" sx={{ width: '100%' }}>
          Guardado!
        </MuiAlert>
      </Snackbar>
      <Snackbar open={isSent} autoHideDuration={6000} onClose={()=>setisSent(false)}>
        <MuiAlert elevation={6} variant="filled"  onClose={()=>setisSent(false)} severity="success" sx={{ width: '100%' }}>
          Enviado!
        </MuiAlert>
      </Snackbar>
      <Dialog onClose={hiddeDialog} open={Boolean(activateUser)}>
        {activateUser && activateUser.type === "mobile" &&
          <>
            <DialogTitle>
              Enviar código de activación
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ¿Quieres enviar ahora un SMS al móvil {activateUser.mobile} con el código de activación?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={hiddeDialog}>Cancelar</Button>
              <Button onClick={sendSMSActivation} autoFocus>Aceptar</Button>
            </DialogActions>
          </>
        }
        {activateUser && activateUser.type === "email" &&
          <>
            <DialogTitle>
              Enviar constraseña
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ¿Quieres enviar ahora un email a {activateUser.email} con una nueva contraseña?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={hiddeDialog}>Cancelar</Button>
              <Button onClick={sendEmailActivation} autoFocus>Aceptar</Button>
            </DialogActions>
          </>
        }

      </Dialog>
      <Dialog onClose={()=>setPrivilegesReview()} open={Boolean(privilegesReview)}>
        <DialogTitle>
          Privilegios del empleado
        </DialogTitle>
        <DialogContent>
          {privilegesReview && privilegesReview.data && privilegesReview.data.map((e,i)=>
            <>
              {e.name && e.type &&
                  <p>
                    <Checkbox
                      color="primary"
                      checked={e.val === "true"}
                      onChange={()=>changePrivilege(e,i)}
                      name="keepsessionopen" />
                      {e.name}
                  </p>
                }
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setPrivilegesReview()}>cerrar</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );

}
